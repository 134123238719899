import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid/Grid';

const styles = theme => ({
	root: {
		textAlign: 'center',
		paddingTop: 80,
		marginTop: 200,
		marginBottom: 200,
	},
});

class Index extends React.Component {

	render () {
		const {classes} = this.props;

		return (
			<Layout pageName={'OwO'}>

				<Helmet>
					<title itemProp="name" lang="en">owo where's Felon?</title>
				</Helmet>

				<div className={classes.root}>

					<Grid container spacing={3} justify={'space-around'} className={classes.grid}>
						<Grid item container xs={12} spacing={3} justify={'center'}>
							<Grid item xs={12} sm={12} md={10}>
								<Typography variant={'h1'}>404</Typography>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={3} justify={'center'}>
							<Grid item container xs={12}>
								<Grid inem xs={12} className={classes.aboutText}>
									<Typography variant={'h2'}>ummm</Typography>
									<Typography variant={'body1'}>what? <p/>Rodina will never forget you going here.
										<p/>Yes. We're not joking. Every visit of this page is being
										tracked.</Typography>
								</Grid>

							</Grid>

						</Grid>
					</Grid>
				</div>
			</Layout>

		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));
